import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/layout'
import { Footer } from '../components/footer'
import { Teaser } from '../components/teaser'

import { Image } from '../components/common/image'
import { Grid, Row, Col } from '../components/common/grid'
import { Space } from '../components/common/space'
import { H4 } from '../components/common/heading'

const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 2px solid #f7f7f7;
`

const title = 'Chimpanzee Natural Nutrition'
const description =
  'Liebevoll gestaltete Aufsteller stellen das Sortiment in den Mittelpunkt'
const keywords = ''

const EnergieRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="point_of_sale" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <p>
              Liebevoll gestaltete Aufsteller, in verschiedenen Größen, stellen
              das Chimpanzee Sortiment in den Mittelpunkt und schaffen
              zusätzlichen Platz im Verkaufsraum.
            </p>

            <p>
              Wir verkaufen die Holzaufsteller kostengünstig und stellen sie bei
              größeren Abnahmemengen kostenlos zur Verfügung.
            </p>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Impressionen</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <ImageWrapper>
            <Image name="stand_small" />
          </ImageWrapper>
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <ImageWrapper>
            <Image name="stand_middle" />
          </ImageWrapper>
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <ImageWrapper>
            <Image name="stand_big" />
          </ImageWrapper>
        </Col>
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default EnergieRiegel
